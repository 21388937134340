<template>
  <div>
    <v-row>
      <v-col class="table_workOrder_data" cols="12">
        <v-row class="row_workOrders_table">
          <v-col class="font_weight_700" cols="12" md="6">Work Orders</v-col>
          <v-col
            class="button_workOrder_table_create text-right"
            cols="12"
            md="6"
          >
            <v-btn
              color="primary"
              class="appbar__btn"
              height="40px"
              @click="dialogWorkOrderUpdate = true"
            >
              <v-icon class="mr-2">{{ icons.add }}</v-icon>
              Add Work Order
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headerWorkOrders"
          :items="workOrders"
          v-model="selected"
          :options.sync="options"
          :footer-props="{
            'items-per-page-options': [5, 10, 15, -1],
          }"
          :items-per-page="5"
          :loading="workOrderFetching"
          @update:options="fetchWorkOrders"
          :server-items-length="totalWorkOrders"
        >
          <template v-slot:item.supplier="{ item }">
            <div v-if="item.supplier">{{ item.supplierName }}</div>
          </template>
          <template v-slot:item.created_at="{ item }">
            <div v-if="item.created_at">{{ item.issueDate }}</div>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editWorkOrder(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteWorkOrder(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="table_quote_data" cols="12">
        <v-row class="row_quotes_table">
          <v-col class="font_weight_700" cols="12" md="6">Quotes</v-col>
          <v-col class="button_quote_table_create text-right" cols="12" md="6">
            <v-btn
              color="primary"
              class="appbar__btn"
              height="40px"
              @click="dialogQuoteUpdate = true"
            >
              <v-icon class="mr-2">{{ icons.add }}</v-icon>
              Add Quote
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headerQuotes"
          :items="quotes"
          v-model="selected"
          :options.sync="options"
          :footer-props="{
            'items-per-page-options': [5, 10, 15, -1],
          }"
          :items-per-page="5"
          :loading="quoteFetching"
          @update:options="fetchQuotes"
          :server-items-length="totalQuotes"
        >
          <template v-slot:item.supplier="{ item }">
            <div v-if="item.supplier">{{ item.supplierName }}</div>
          </template>
          <template v-slot:item.file="{ item }">
            <div v-if="item.file">{{ item.fileName }}</div>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editQuote(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteQuote(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="table_invoice_data" cols="12">
        <v-row class="row_invoices_table">
          <v-col class="font_weight_700" cols="12" md="6">Invoices</v-col>
          <v-col class="button_quote_table_create text-right" cols="12" md="6">
            <v-btn
              color="primary"
              class="appbar__btn"
              height="40px"
              @click="handleOpenNewInvoice"
            >
              <v-icon class="mr-2">{{ icons.add }}</v-icon>
              Add Invoice
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headerInvoices"
          :items="invoices"
          v-model="selected"
          :options.sync="options"
          :footer-props="{
            'items-per-page-options': [5, 10, 15, -1],
          }"
          :items-per-page="5"
          :loading="invoiceFetching"
          @update:options="fetchInvoices"
          :server-items-length="totalInvoices"
        >
          <template v-slot:item.supplier="{ item }">
            <div v-if="item.supplier">{{ item.supplierName }}</div>
          </template>
          <template v-slot:item.file="{ item }">
            <div v-if="item.file">{{ item.fileName }}</div>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editInvoice(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteInvoice(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogWorkOrderUpdate" max-width="600px">
      <v-form
        ref="formWorkOrder"
        class="faq-form__form"
        @submit.prevent="submitWorkOrder"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            Open Dialog
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">New Work Order</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <label class="text-field-label">Supplier</label>
                  <SelectSupplier
                    flat
                    solo
                    required
                    class="mt-2"
                    hide-details="auto"
                    v-model="formWorkOrder.supplier_id"
                    :pre-select="!isUpdateWorkOrder"
                    :error-messages="formWorkOrder.$getError('supplier_id')"
                    :loading="formWorkOrder.$busy"
                    :disabled="formWorkOrder.$busy"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <label class="text-field-label">Title</label>
                  <v-text-field
                    flat
                    solo
                    required
                    class="mt-2"
                    placeholder="Title"
                    v-model="formWorkOrder.title"
                    :loading="formWorkOrder.$busy"
                    :disabled="formWorkOrder.$busy"
                    :error-messages="formWorkOrder.$getError('title')"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="text_uppercase"
              color="blue darken-1 "
              text
              @click="cancelDialogWorkOrderUpdate()"
            >
              Cancel
            </v-btn>
            <v-btn
              class="text_uppercase"
              color="blue darken-1 text-uppercase"
              text
              @click="submitWorkOrder()"
              name="submitFormDocument"
              :loading="formWorkOrder.$busy"
              :disabled="!formWorkOrder.supplier_id || !formWorkOrder.title"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="dialogWorkOrderDelete" max-width="600px">
      <v-form
        ref="formWorkOrder"
        class="faq-form__form"
        @submit.prevent="submitWorkOrder"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5"
              >Are you sure to delete this work order(s) ?</span
            >
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="cancelDialogWorkOrderDelete()"
            >
              No
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="confirmWorkOrderDelete()"
              name="submitFormWorkOrder"
              :loading="deleteLoading"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="dialogQuoteUpdate" max-width="600px">
      <v-form
        ref="formQuote"
        class="faq-form__form"
        @submit.prevent="submitQuote"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            Open Dialog
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">New Quote</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="4">
                  <label class="text-field-label">File Input</label>
                  <v-file-input
                    v-model="formQuote.file"
                    :loading="formQuote.$busy"
                    :disabled="formQuote.$busy"
                    class="mt-2"
                    flat
                    solo
                    clearable
                    show-close="false"
                    :error-messages="
                      isFileQuoteTooLarge
                        ? 'The file must be less than 4MB'
                        : formQuote.$getError('file_input')
                    "
                  ></v-file-input>
                </v-col>
                <v-col cols="12" md="4">
                  <label class="text-field-label">Supplier</label>
                  <SelectSupplier
                    flat
                    solo
                    required
                    class="mt-2"
                    hide-details="auto"
                    v-model="formQuote.supplier_id"
                    :pre-select="!isUpdateQuote"
                    :error-messages="formQuote.$getError('supplier_id')"
                    :loading="formQuote.$busy"
                    :disabled="formQuote.$busy"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <label class="text-field-label">Amount</label>
                  <v-text-field
                    flat
                    solo
                    required
                    type="number"
                    min="0"
                    class="mt-2"
                    placeholder="Amount"
                    v-model="formQuote.amount"
                    :loading="formQuote.$busy"
                    :disabled="formQuote.$busy"
                    :error-messages="formQuote.$getError('amount')"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="text_uppercase"
              color="blue darken-1 "
              text
              @click="cancelDialogQuoteUpdate()"
            >
              Cancel
            </v-btn>
            <v-btn
              class="text_uppercase"
              color="blue darken-1 text-uppercase"
              text
              @click="submitQuote()"
              name="submitFormDocument"
              :loading="formQuote.$busy"
              :disabled="!formQuote.supplier_id || !formQuote.amount"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="dialogQuoteDelete" max-width="600px">
      <v-form
        ref="formQuote"
        class="faq-form__form"
        @submit.prevent="submitQuote"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Are you sure to delete this quote(s) ?</span>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="cancelDialogQuoteDelete()"
            >
              No
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="confirmQuoteDelete()"
              name="submitFormQuote"
              :loading="deleteLoading"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="dialogInvoiceUpdate" max-width="1000px">
      <v-form
        ref="formInvoice"
        class="faq-form__form"
        @submit.prevent="submitQuote"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            Open Dialog
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">New Invoice</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="5">
                  <v-row>
                    <v-col cols="12">
                      <label class="text-field-label">File Input</label>
                      <v-file-input
                        v-model="formInvoice.file"
                        :loading="formInvoice.$busy"
                        :disabled="formInvoice.$busy"
                        flat
                        solo
                        clearable
                        show-close="false"
                        hide-details="auto"
                        :error-messages="
                          !isPDFfile && this.formInvoice.file
                            ? 'The file must be a file of type: pdf'
                            : isFileInvoiceTooLarge
                            ? 'The file must be less than 4MB'
                            : formInvoice.$getError('file')
                        "
                      ></v-file-input>
                    </v-col>
                    <v-col cols="12">
                      <label class="text-field-label">Supplier</label>
                      <SelectSupplier
                        flat
                        solo
                        required
                        hide-details="auto"
                        v-model="formInvoice.supplier_id"
                        :pre-select="!isUpdateQuote"
                        :error-messages="formInvoice.$getError('supplier_id')"
                        :loading="formInvoice.$busy"
                        :disabled="formInvoice.$busy"
                      />
                    </v-col>
                    <v-col cols="12">
                      <label class="text-field-label">Invoice #</label>
                      <v-text-field
                        flat
                        solo
                        required
                        placeholder="Invoice"
                        v-model="formInvoice.invoice_number"
                        :loading="formInvoice.$busy"
                        :disabled="formInvoice.$busy"
                        :error-messages="
                          formInvoice.$getError('invoice_number')
                        "
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <label class="text-field-label">Account</label>
                      <SelectAccount
                        flat
                        solo
                        required
                        hide-details="auto"
                        v-model="formInvoice.chart_of_account_id"
                        :pre-select="!isUpdateInvoice"
                        :error-messages="
                          formInvoice.$getError('chart_of_account_id')
                        "
                        :loading="formInvoice.$busy"
                        :disabled="formInvoice.$busy"
                        join-label
                      />
                    </v-col>
                    <v-col cols="12">
                      <label class="text-field-label">Due Date</label>
                      <v-text-field
                        flat
                        solo
                        required
                        hide-details="auto"
                        placeholder="Due Date"
                        v-model="formInvoice.due_date"
                        :loading="formInvoice.$busy"
                        :disabled="formInvoice.$busy"
                        :error-messages="
                          !this.isValidDueDate ? 'Invalid Date Format' : ''
                        "
                        @change="handleChangeDueDate"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <label class="text-field-label">Amount</label>
                      <v-text-field
                        flat
                        solo
                        required
                        type="number"
                        min="0"
                        placeholder="Amount"
                        v-model="formInvoice.amount"
                        :loading="formInvoice.$busy"
                        :disabled="formInvoice.$busy"
                        :error-messages="formInvoice.$getError('amount')"
                        hide-details="auto"
                        @keypress="onlyForCurrency"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <label class="text-field-label">Description</label>
                      <v-textarea
                        flat
                        solo
                        requiredcancelDialogInvoiceUpdate
                        hide-details="auto"
                        placeholder="Description"
                        v-model="formInvoice.description"
                        :error-messages="formInvoice.$getError('description')"
                        :loading="formInvoice.$busy"
                        :disabled="formInvoice.$busy"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="7">
                  <PreviewPDF :file="formInvoice.file" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="text_uppercase"
              color="blue darken-1 "
              text
              @click="cancelDialogInvoiceUpdate()"
            >
              Cancel
            </v-btn>
            <v-btn
              class="text_uppercase"
              color="blue darken-1 text-uppercase"
              text
              @click="submitInvoice()"
              name="submitFormDocument"
              :loading="formInvoice.$busy"
              :disabled="
                !formInvoice.supplier_id ||
                !formInvoice.amount ||
                !isValidDueDate
              "
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="dialogInvoiceDelete" max-width="600px">
      <v-form
        ref="formInvoice"
        class="faq-form__form"
        @submit.prevent="submitInvoice"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5"
              >Are you sure to delete this invoice(s) ?</span
            >
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="cancelDialogInvoiceDelete()"
            >
              No
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="confirmInvoiceDelete()"
              name="submitFormInvoice"
              :loading="deleteLoading"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { mdiPlus } from '@mdi/js'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import SelectSupplier from '@/components/fields/SelectSupplier.vue'
import PreviewPDF from '@/views/Home/Maintenance/Requests/components/PreviewPDF.vue'
import SelectAccount from '@/components/fields/SelectAccount.vue'
// import DatetimePicker from '@/components/fields/DatetimePicker.vue'
import { dateFormat } from '@/utils/date'
import DATETIME_FORMAT from '@/utils/enums/DatetimeFormat'

export default {
  components: { SelectAccount, PreviewPDF, SelectSupplier },
  props: {
    maintenanceId: {
      required: true,
      type: [String, Number],
    },
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  data() {
    return {
      headerWorkOrders: [
        {
          text: 'Supplier',
          value: 'supplier',
        },
        {
          text: 'Title',
          value: 'title',
        },
        {
          text: 'Created Date',
          value: 'created_at',
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'end',
          sortable: false,
        },
      ],
      dialogWorkOrderUpdate: false,
      dialogWorkOrderDelete: false,
      isUpdateWorkOrder: false,
      deleteWorkOrderId: '',
      updateWorkOrderId: '',
      workOrderFetching: false,
      formWorkOrder: new Form({
        supplier_id: '',
        maintenance_id: '',
        title: '',
      }),
      headerQuotes: [
        {
          text: 'Supplier',
          value: 'supplier',
        },
        {
          text: 'Amount',
          value: 'amount',
        },
        {
          text: 'File Name',
          value: 'file',
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'end',
          sortable: false,
        },
      ],
      dialogQuoteUpdate: false,
      dialogQuoteDelete: false,
      isUpdateQuote: false,
      deleteQuoteId: '',
      updateQuoteId: '',
      quoteFetching: false,
      formQuote: new Form({
        supplier_id: '',
        maintenance_id: '',
        amount: '',
        file: '',
      }),
      headerInvoices: [
        {
          text: 'Supplier',
          value: 'supplierName',
        },
        {
          text: 'Amount',
          value: 'amount',
        },
        {
          text: 'File Name',
          value: 'fileName',
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'end',
          sortable: false,
        },
      ],
      dialogInvoiceUpdate: false,
      dialogInvoiceDelete: false,
      isUpdateInvoice: false,
      deleteInvoiceId: '',
      updateInvoiceId: '',
      invoiceFetching: false,
      formInvoice: new Form({
        supplier_id: '',
        maintenance_id: '',
        chart_of_account_id: '',
        amount: '',
        file: null,
        invoice_number: '',
        description: '',
        due_date: '',
      }),
      selected: [],
      options: {},
      deleteLoading: false,
      icons: {
        add: mdiPlus,
      },
      isValidDueDate: true,
    }
  },

  computed: {
    ...mapState({
      permissions: (state) => state.auth.permissions,
      workOrders: (state) => state.workOrder.list,
      totalWorkOrders: (state) => state.workOrder.totalWorkOrders,
      errorWorkOrder: (state) => state.workOrder.error,
      quotes: (state) => state.quote.list,
      totalQuotes: (state) => state.quote.totalQuotes,
      errorQuote: (state) => state.quote.error,
      invoices: (state) => state.invoice.list,
      totalInvoices: (state) => state.invoice.totalInvoices,
      errorInvoice: (state) => state.invoice.error,
      currentAction: (state) => state.maintenance.currentAction,
    }),

    submitApiWorkOrder() {
      return this.isUpdateWorkOrder
        ? this.updateWorkOrder
        : this.createWorkOrder
    },

    submitApiQuote() {
      return this.isUpdateQuote ? this.updateQuote : this.createQuote
    },

    submitApiInvoice() {
      return this.isUpdateInvoice ? this.updateInvoice : this.createInvoice
    },

    isFileQuoteTooLarge() {
      if (this.formQuote.file) {
        return this.formQuote.file.size > 4 * 1024 * 1024
      }
      return false
    },

    isFileInvoiceTooLarge() {
      if (this.formInvoice.file) {
        return this.formInvoice.file.size > 4 * 1024 * 1024
      }
      return false
    },

    isPDFfile() {
      if (this.formInvoice.file) {
        return (
          (this.formInvoice.file.type || this.formInvoice.file.mime_type) ===
          'application/pdf'
        )
      }
      return false
    },
  },

  watch: {
    currentAction(newValue, oldValue) {
      switch (newValue) {
        case 'add_quote':
          this.dialogQuoteUpdate = true
          break
        default:
          break
      }
    },
  },

  methods: {
    ...mapActions({
      getWorkOrders: 'workOrder/getWorkOrders',
      createWorkOrder: 'workOrder/createWorkOrder',
      updateWorkOrder: 'workOrder/updateWorkOrder',
      deleteWorkOrders: 'workOrder/deleteWorkOrder',
      clearWorkOrders: 'workOrder/clearTempWorkOrders',
      deleteWorkOrder: 'workOrder/deleteTempWorkOrder',
      getQuotes: 'quote/getQuotes',
      createQuote: 'quote/createQuote',
      updateQuote: 'quote/updateQuote',
      deleteQuotes: 'quote/deleteQuote',
      clearQuotes: 'quote/clearTempQuotes',
      deleteQuote: 'quote/deleteTempQuote',

      getInvoices: 'invoice/getInvoices',
      createInvoice: 'invoice/createInvoice',
      updateInvoice: 'invoice/updateInvoice',
      deleteInvoices: 'invoice/deleteInvoice',
    }),

    onlyForCurrency($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which

      // only allow number and one dot
      if (
        (keyCode < 48 || keyCode > 57) &&
        (keyCode !== 46 || this.formInvoice.amount.indexOf('.') !== -1)
      ) {
        $event.preventDefault()
      }

      // restrict to 2 decimal places
      if (
        this.formInvoice.amount !== null &&
        this.formInvoice.amount.indexOf('.') > -1 &&
        this.formInvoice.amount.split('.')[1].length > 1
      ) {
        $event.preventDefault()
      }
    },

    createDateByInput(dateString) {
      const [day, month, year] = dateString.split(/[-:/]/).map(Number)
      return new Date(`${month}/${day}/${year}`)
    },

    isValidDateString(dateString) {
      return !isNaN(this.createDateByInput(dateString).getTime())
    },

    handleChangeDueDate(date) {
      if (this.isValidDateString(date)) {
        this.isValidDueDate = true
      } else {
        this.isValidDueDate = false
      }
    },

    async fetchWorkOrders(options, page = 1) {
      let params = {
        page: options?.page || 1,
        itemsPerPage: options?.itemsPerPage
          ? options?.itemsPerPage === -1
            ? this.totalInsurances
            : options.itemsPerPage
          : 5,
        sort: options?.sortBy[0]
          ? options.sortDesc[0]
            ? '-' + options.sortBy[0]
            : options.sortBy[0]
          : '',
        maintenanceId: this.maintenanceId,
      }

      this.workOrderFetching = true
      await this.getWorkOrders(params).finally(() => {
        this.workOrderFetching = false
      })
    },

    clearModalInvoice(showModal) {
      this.dialogInvoiceUpdate = showModal
      this.isUpdateInvoice = false
      this.formInvoice = new Form({
        supplier_id: '',
        maintenance_id: '',
        chart_of_account_id: '',
        amount: '',
        file: null,
        invoice_number: '',
        description: '',
        due_date: '',
      })
      this.isValidDueDate = true
    },

    handleOpenNewInvoice() {
      this.clearModalInvoice(true)
    },

    async fetchQuotes(options, page = 1) {
      let params = {
        page: options?.page || 1,
        itemsPerPage: options?.itemsPerPage
          ? options?.itemsPerPage === -1
            ? this.totalInsurances
            : options.itemsPerPage
          : 5,
        sort: options?.sortBy[0]
          ? options.sortDesc[0]
            ? '-' + options.sortBy[0]
            : options.sortBy[0]
          : '',
        maintenanceId: this.maintenanceId,
      }

      this.quoteFetching = true
      await this.getQuotes(params).finally(() => {
        this.quoteFetching = false
      })
    },

    async fetchInvoices(options, page = 1) {
      let params = {
        options: {
          page: options?.page || 1,
          itemsPerPage: options?.itemsPerPage
            ? options?.itemsPerPage === -1
              ? this.totalInsurances
              : options.itemsPerPage
            : 5,
          sort: options?.sortBy[0]
            ? options.sortDesc[0]
              ? '-' + options.sortBy[0]
              : options.sortBy[0]
            : '',
        },
        maintenanceId: this.maintenanceId,
      }

      this.invoiceFetching = true
      await this.getInvoices(params).finally(() => {
        this.invoiceFetching = false
      })
    },

    editWorkOrder(item) {
      this.dialogWorkOrderUpdate = true
      this.isUpdateWorkOrder = true
      this.updateWorkOrderId = item.id
      this.formWorkOrder.title = item.title
      this.formWorkOrder.supplier_id = item.supplier_id
    },

    editQuote(item) {
      this.dialogQuoteUpdate = true
      this.isUpdateQuote = true
      this.updateQuoteId = item.id
      this.formQuote.file = item.file
      this.formQuote.amount = item.amount
      this.formQuote.supplier_id = item.supplier_id
    },

    editInvoice(item) {
      if (!item) return
      this.dialogInvoiceUpdate = true
      this.isUpdateInvoice = true
      this.updateInvoiceId = item.id
      this.formInvoice.amount = item.amount
      this.formInvoice.supplier_id = item.supplier_id
      this.formInvoice.chart_of_account_id = item.chart_of_account_id
      this.formInvoice.description = item.description
      this.formInvoice.invoice_number = item.invoice_number
      this.formInvoice.due_date = dateFormat(
        item.due_date,
        DATETIME_FORMAT.shortDateFormat
      )
      this.formInvoice.file = item.file
    },

    cancelDialogWorkOrderUpdate() {
      this.dialogWorkOrderUpdate = false
      this.isUpdateWorkOrder = false
      this.formWorkOrder = new Form({
        supplier_id: '',
        maintenance_id: '',
        title: '',
      })
    },

    cancelDialogQuoteUpdate() {
      this.dialogQuoteUpdate = false
      this.isUpdateQuote = false
      this.formQuote = new Form({
        supplier_id: '',
        maintenance_id: '',
        amount: '',
        file: '',
      })
    },

    cancelDialogInvoiceUpdate() {
      this.clearModalInvoice(false)
    },

    cancelDialogWorkOrderDelete() {
      this.dialogWorkOrderDelete = false
    },

    cancelDialogQuoteDelete() {
      this.dialogQuoteDelete = false
    },

    cancelDialogInvoiceDelete() {
      this.dialogInvoiceDelete = false
    },

    deleteWorkOrder(item) {
      this.dialogWorkOrderDelete = true
      this.deleteWorkOrderId = item.id
    },

    deleteQuote(item) {
      this.dialogQuoteDelete = true
      this.deleteQuoteId = item.id
    },

    deleteInvoice(item) {
      this.dialogInvoiceDelete = true
      this.deleteInvoiceId = item.id
    },

    async confirmWorkOrderDelete() {
      this.deleteLoading = true
      await this.deleteWorkOrders(this.deleteWorkOrderId).then(() => {
        this.deleteWorkOrders(this.deleteWorkOrderId)
        this.showSnackbar('Delete Work Order successfully!')
        this.dialogWorkOrderDelete = false
        this.deleteLoading = false
        this.fetchWorkOrders()
      })
    },

    async confirmQuoteDelete() {
      this.deleteLoading = true
      await this.deleteQuotes(this.deleteQuoteId).then(() => {
        this.deleteQuotes(this.deleteQuoteId)
        this.showSnackbar('Delete Quote successfully!')
        this.dialogQuoteDelete = false
        this.deleteLoading = false
        this.fetchQuotes()
      })
    },

    async confirmInvoiceDelete() {
      this.deleteLoading = true
      await this.deleteInvoices(this.deleteInvoiceId).then(() => {
        this.deleteInvoices(this.deleteInvoiceId)
        this.showSnackbar('Delete Invoice successfully!')
        this.dialogInvoiceDelete = false
        this.deleteLoading = false
        this.fetchInvoices()
      })
    },

    async submitWorkOrder() {
      if (this.formWorkOrder.$busy) return
      this.formWorkOrder.$busy = true
      this.formWorkOrder.$clearErrors()
      let forUpdate = this.isUpdateWorkOrder
      const formData = this.getFormWorkOrderData()
      const id = this.updateWorkOrderId
      await this.submitApiWorkOrder({ formData, id })
        .then((res) => {
          if (this.errorWorkOrder) {
            this.showSnackbar(this.errorWorkOrder, false)
            this.clearError()
          } else {
            if (forUpdate) {
              this.formWorkOrder.$busy = false
              this.showSnackbar('Work Order details successfully updated!')
            } else {
              this.showSnackbar('Work Order successfully created!')
            }
          }
          this.isUpdateWorkOrder = false
          this.dialogWorkOrderUpdate = false
          this.fetchWorkOrders()
          this.formWorkOrder = new Form({
            supplier_id: '',
            maintenance_id: '',
            title: '',
          })
        })
        .catch((err) => {
          this.formWorkOrder.$busy = false
          this.formWorkOrder.$setErrors(this.getValidationErrors(err))
        })
    },

    async submitQuote() {
      if (this.formQuote.$busy) return
      this.formQuote.$busy = true
      this.formQuote.$clearErrors()
      let forUpdate = this.isUpdateQuote
      const formData = this.getFormQuoteData()
      const id = this.updateQuoteId
      await this.submitApiQuote({ formData, id })
        .then((res) => {
          if (this.errorQuote) {
            this.showSnackbar(this.errorQuote, false)
            this.clearError()
          } else {
            if (forUpdate) {
              this.formQuote.$busy = false
              this.showSnackbar('Quote details successfully updated!')
            } else {
              this.showSnackbar('Quote successfully created!')
            }
          }
          this.isUpdateQuote = false
          this.dialogQuoteUpdate = false
          this.fetchQuotes()
          this.formQuote = new Form({
            supplier_id: '',
            maintenance_id: '',
            amount: '',
            file: '',
          })
        })
        .catch((err) => {
          this.formQuote.$busy = false
          this.formQuote.$setErrors(this.getValidationErrors(err))
        })
    },

    async submitInvoice() {
      if (this.formInvoice.$busy) return
      this.formInvoice.$busy = true
      this.formInvoice.$clearErrors()
      let forUpdate = this.isUpdateInvoice
      const formData = this.getFormInvoiceData()
      const id = this.updateInvoiceId
      await this.submitApiInvoice({ formData, id })
        .then((res) => {
          if (this.errorInvoice) {
            this.showSnackbar(this.errorInvoice, false)
            this.clearError()
          } else {
            if (forUpdate) {
              this.formInvoice.$busy = false
              this.showSnackbar('Invoice details successfully updated!')
            } else {
              this.showSnackbar('Invoice successfully created!')
            }
          }
          this.isUpdateInvoice = false
          this.dialogInvoiceUpdate = false
          this.fetchInvoices()
          this.formInvoice = new Form({
            supplier_id: '',
            maintenance_id: '',
            chart_of_account_id: '',
            amount: '',
            file: null,
            invoice_number: '',
            description: '',
            due_date: '',
          })
        })
        .catch((err) => {
          this.formInvoice.$busy = false
          this.formInvoice.$setErrors(this.getValidationErrors(err))
        })
    },

    getFormWorkOrderData() {
      let form = this.formWorkOrder.$data()
      form.maintenance_id = this.maintenanceId

      const formData = new FormData()
      for (var field in form) {
        formData.append(field, form[field])
      }

      return formData
    },

    getFormQuoteData() {
      let form = this.formQuote.$data()
      form.maintenance_id = this.maintenanceId

      let file = form.file

      if (!(file instanceof Blob) && !(file instanceof File)) {
        delete form.file
      }

      const formData = new FormData()
      for (var field in form) {
        formData.append(field, form[field])
      }

      return formData
    },

    getFormInvoiceData() {
      let form = this.formInvoice.$data()
      form.maintenance_id = this.maintenanceId

      let file = form.file

      if (!(file instanceof Blob) && !(file instanceof File)) {
        delete form.file
      }

      const formData = new FormData()
      for (var field in form) {
        if (field === 'due_date') {
          formData.append(
            field,
            this.createDateByInput(form[field]).toISOString()
          )
        } else {
          formData.append(field, form[field])
        }
      }

      return formData
    },
  },
}
</script>
<style scoped>
::v-deep .faq-form__form .col {
  padding: 0px 12px 12px;
}
</style>
