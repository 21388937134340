<template>
  <div class="maintenance-details py-8">
    <RequestAppbar />

    <v-row class="mx-4">
      <v-col cols="12" class="ma-0 pa-0">
        <v-row>
          <v-col cols="12" md="5">
            <ViewingTitle
              :title="displayName"
              class="maintenance-details__title"
            />
          </v-col>
          <v-col cols="12" md="1">
            <DeleteOrRestore
              v-if="hasDeletePermissions"
              :model="maintenance"
              :max-width="500"
              name="maintenance request"
              redirect="/maintenance/dashboard"
            />
          </v-col>
          <v-col cols="12" md="6">
            <div class="maintenance-details__status_action">
              <div class="maintenance-details__status">
                <Status
                  v-if="nextStatus"
                  :status="nextStatus"
                  :loading="loadingAction"
                  @click.native="
                    loadingAction ? null : updateStatus(nextStatus)
                  "
                />
              </div>
              <div class="maintenance-details__action">
                <RequestAction
                  flat
                  solo
                  hide-details="auto"
                  class="mt-2"
                  placeholder="Description"
                  :loading="loadingAction"
                />
              </div>
            </div>
          </v-col>
        </v-row>

        <div class="d-flex mb-6 mt-4">
          <RequestForm
            class="maintenance-details__form"
            :maintenance="maintenance"
          />
        </div>
      </v-col>
    </v-row>

    <Loading :value="loading" />

    <v-dialog v-model="dialogSendWorkOrder" max-width="800px">
      <v-form
        ref="formQuote"
        class="faq-form__form"
        @submit.prevent="sendWorkOrderHandle()"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            Open Dialog
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">Send Work Order</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <label class="text-field-label">Supplier</label>
                  <SelectSupplier
                    flat
                    solo
                    required
                    class="mt-2"
                    hide-details="auto"
                    v-model="sendWorkOrderForm.supplier_id"
                    :pre-select="false"
                    :error-messages="sendWorkOrderForm.$getError('supplier_id')"
                    :loading="sendWorkOrderForm.$busy"
                    :disabled="sendWorkOrderForm.$busy"
                    @input="getTemplatePreviewHandle"
                  />
                </v-col>
                <v-col cols="12">
                  <label class="text-field-label"
                    >Additional notes for job</label
                  >
                  <v-textarea
                    flat
                    solo
                    required
                    rows="2"
                    hide-details="auto"
                    class="mt-2"
                    placeholder="Additional notes for job"
                    v-model="sendWorkOrderForm.additional_notes_for_job"
                    :error-messages="
                      sendWorkOrderForm.$getError('additional_notes_for_job')
                    "
                    :loading="sendWorkOrderForm.$busy"
                    :disabled="sendWorkOrderForm.$busy"
                    @input="debouncedGetTemplatePreviewHandle"
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <label class="text-field-label mb-4">
                    Email message preview
                    <v-progress-circular
                      v-if="templatePreviewLoading"
                      indeterminate
                      class="ml-2"
                      color="primary"
                      size="20"
                    ></v-progress-circular>
                  </label>
                  <div
                    class="email-message-preview"
                    v-html="templatePreview || sendWorkOrderForm.email_message"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="text_uppercase"
              color="blue darken-1 "
              text
              @click="cancelDialogSendWorkOrder()"
            >
              Cancel
            </v-btn>
            <v-btn
              class="text_uppercase"
              color="blue darken-1 text-uppercase"
              text
              @click="sendWorkOrderHandle()"
              name="submitFormSendWorkOrder"
              :loading="sendWorkOrderForm.$busy"
              :disabled="!sendWorkOrderForm.supplier_id"
            >
              Send
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="dialogSendRequestQuote" max-width="800px">
      <v-form
        ref="formQuote"
        class="faq-form__form"
        @submit.prevent="sendRequestQuoteHandle()"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            Open Dialog
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">Send Request Quote</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <label class="text-field-label">Supplier(s)</label>
                  <SelectSupplier
                    flat
                    solo
                    required
                    class="mt-2"
                    hide-details="auto"
                    v-model="sendRequestQuoteForm.supplier_ids"
                    :pre-select="false"
                    :error-messages="
                      sendRequestQuoteForm.$getError('supplier_ids')
                    "
                    :loading="sendRequestQuoteForm.$busy"
                    :disabled="sendRequestQuoteForm.$busy"
                    :multiple="true"
                    @input="getTemplatePreviewHandle"
                  />
                </v-col>
                <v-col cols="12">
                  <label class="text-field-label"
                    >Additional notes for job</label
                  >
                  <v-textarea
                    flat
                    solo
                    required
                    rows="2"
                    hide-details="auto"
                    class="mt-2"
                    placeholder="Additional notes for job"
                    v-model="sendRequestQuoteForm.additional_notes_for_job"
                    :error-messages="
                      sendRequestQuoteForm.$getError('additional_notes_for_job')
                    "
                    :loading="sendRequestQuoteForm.$busy"
                    :disabled="sendRequestQuoteForm.$busy"
                    @input="debouncedGetTemplatePreviewHandle"
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <label class="text-field-label mb-4">
                    Email message preview
                    <v-progress-circular
                      v-if="templatePreviewLoading"
                      indeterminate
                      class="ml-2"
                      color="primary"
                      size="20"
                    ></v-progress-circular>
                  </label>
                  <div
                    class="email-message-preview"
                    v-html="
                      templatePreview || sendRequestQuoteForm.email_message
                    "
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="text_uppercase"
              color="blue darken-1 "
              text
              @click="cancelDialogSendRequestQuote()"
            >
              Cancel
            </v-btn>
            <v-btn
              class="text_uppercase"
              color="blue darken-1 text-uppercase"
              text
              @click="sendRequestQuoteHandle()"
              name="submitFormSendRequestQuote"
              :loading="sendRequestQuoteForm.$busy"
              :disabled="!sendRequestQuoteForm.supplier_ids"
            >
              Send
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * View Maintenance Request
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import ViewingTitle from '@/components/common/ViewingTitle'
import RequestForm from '@/views/Home/Maintenance/Requests/components/RequestForm'
import RequestAppbar from '@/views/Home/Maintenance/Requests/components/RequestAppbar'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Loading from '@/components/common/Loading'
import DeleteOrRestore from '@/components/fields/DeleteOrRestore'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import Status from '@/components/common/Status.vue'
import RequestAction from '@/views/Home/Maintenance/Requests/components/RequestAction.vue'
import SelectSupplier from '@/components/fields/SelectSupplier.vue'
import debounce from 'lodash/debounce'
import Form from '@/utils/form'

export default {
  name: 'MaintenanceDetails',

  components: {
    SelectSupplier,
    RequestAction,
    Status,
    ViewingTitle,
    RequestAppbar,
    RequestForm,
    Loading,
    DeleteOrRestore,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  data() {
    return {
      loading: false,
      loadingAction: false,
      tabItems: [{ tab: 'Info' }],
      dialogSendWorkOrder: false,
      sendWorkOrderForm: new Form({
        supplier_id: '',
        additional_notes_for_job: '',
        email_message: '',
      }),
      dialogSendRequestQuote: false,
      sendRequestQuoteForm: new Form({
        supplier_ids: [],
        additional_notes_for_job: '',
        email_message: '',
      }),
      templatePreviewLoading: false,
      templatePreview: null,
    }
  },

  computed: {
    ...mapState({
      maintenance: (state) => state.maintenance.maintenanceDetails,
      permissions: (state) => state.auth.permissions,
      currentAction: (state) => state.maintenance.currentAction,
      sendWorkOrderTemplate: (state) => state.template.sendWorkOrderTemplate,
      sendRequestQuoteTemplate: (state) =>
        state.template.sendRequestQuoteTemplate,
    }),

    displayName() {
      return this.maintenance ? this.maintenance.title : null
    },

    hasDeletePermissions() {
      return validatePermissions(
        [PERMISSION.MAINTENANCES_DELETE],
        this.permissions
      )
    },
    nextStatus() {
      if (!this.maintenance) return null

      if (this.maintenance.status.includes('pending')) {
        return 'in_progress'
      } else if (this.maintenance.status.includes('in_progress')) {
        return 'completed'
      }

      return null
    },
  },

  methods: {
    ...mapActions({
      getMaintenanceDetails: 'maintenance/getMaintenanceDetails',
      updateMaintenanceStatus: 'maintenance/updateStatus',
      sendWorkOrder: 'maintenance/sendWorkOrder',
      sendRequestQuote: 'maintenance/sendRequestQuote',
      getSendWorkOrderTemplate: 'template/getSendWorkOrderTemplate',
      getSendRequestQuoteTemplate: 'template/getSendRequestQuoteTemplate',
      getTemplatePreview: 'maintenance/getTemplatePreview',
    }),

    ...mapMutations({
      clearMaintenanceDetails: 'maintenance/clearMaintenanceDetails',
    }),

    async getMaintenance() {
      this.loading = true
      await this.getMaintenanceDetails(this.$route.params.id)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },

    async updateStatus(status) {
      if (status === 'completed') status = 'finished'
      this.loadingAction = true

      const formData = new FormData()

      formData.append('status', status)
      formData.append('id', this.maintenance.id)

      await this.updateMaintenanceStatus(formData)
        .then(() => {
          this.loadingAction = false
          this.showSnackbar('Maintenance status details successfully updated!')
        })
        .catch((err) => {
          this.loadingAction = false
          this.form.$setErrors(this.getValidationErrors(err))
        })
    },

    async sendWorkOrderHandle() {
      this.loadingAction = true

      let form = this.sendWorkOrderForm.$data()

      form.id = this.maintenance.id

      this.sendWorkOrderForm.$busy = true
      this.sendWorkOrderForm.$clearErrors()

      await this.sendWorkOrder(form)
        .then(() => {
          this.loadingAction = false
          this.sendWorkOrderForm.$busy = false
          this.dialogSendWorkOrder = false
          this.showSnackbar('Work order is sent successfully!')
          this.sendWorkOrderForm = new Form({
            supplier_id: '',
            additional_notes_for_job: '',
            email_message: '',
          })
        })
        .catch((err) => {
          this.loadingAction = false
          this.sendWorkOrderForm.$busy = false
          this.sendWorkOrderForm.$setErrors(this.getValidationErrors(err))
        })
    },

    cancelDialogSendWorkOrder() {
      this.dialogSendWorkOrder = false
      this.sendWorkOrderForm = new Form({
        supplier_id: '',
        additional_notes_for_job: '',
        email_message: '',
      })
    },

    async sendRequestQuoteHandle() {
      this.loadingAction = true

      let form = this.sendRequestQuoteForm.$data()

      form.id = this.maintenance.id

      this.sendRequestQuoteForm.$busy = true
      this.sendRequestQuoteForm.$clearErrors()

      await this.sendRequestQuote(form)
        .then(() => {
          this.loadingAction = false
          this.sendRequestQuoteForm.$busy = false
          this.dialogSendRequestQuote = false
          this.showSnackbar('Request Quote is sent successfully!')
          this.sendRequestQuoteForm = new Form({
            supplier_ids: [],
            additional_notes_for_job: '',
            email_message: '',
          })
        })
        .catch((err) => {
          this.loadingAction = false
          this.sendRequestQuoteForm.$busy = false
          this.sendRequestQuoteForm.$setErrors(this.getValidationErrors(err))
        })
    },

    cancelDialogSendRequestQuote() {
      this.dialogSendRequestQuote = false
      this.sendRequestQuoteForm = new Form({
        supplier_ids: [],
        additional_notes_for_job: '',
        email_message: '',
      })
    },

    async getTemplatePreviewHandle() {
      const form = this.getTemplatePreviewFormData()
      if (!form) return

      this.templatePreviewLoading = true

      await this.getTemplatePreview(form)
        .then((response) => {
          this.templatePreviewLoading = false
          this.templatePreview = response
        })
        .catch((err) => {
          this.showSnackbar(err, false)
          this.templatePreviewLoading = false
        })
    },

    getTemplatePreviewFormData() {
      let form = null
      if (this.dialogSendRequestQuote) {
        form = this.sendRequestQuoteForm.$data()
        if (!form.supplier_ids[0]) return null
        form.email_template_name = 'Request Quote'
        form.supplier_id = form.supplier_ids[0]
      } else {
        form = this.sendWorkOrderForm.$data()
        form.email_template_name = 'Send Work Order'
      }

      if (form.additional_notes_for_job.length === 0) return null

      form.id = this.maintenance.id

      return form
    },
  },

  created() {
    this.getMaintenance()

    if (!this.sendWorkOrderTemplate) this.getSendWorkOrderTemplate()
    if (!this.sendRequestQuoteTemplate) this.getSendRequestQuoteTemplate()

    this.debouncedGetTemplatePreviewHandle = debounce(
      this.getTemplatePreviewHandle,
      500
    )
  },

  destroyed() {
    this.clearMaintenanceDetails()
  },

  watch: {
    $route() {
      this.getMaintenance()
    },
    currentAction(newValue, oldValue) {
      switch (newValue) {
        case 'send_for_inspection':
          this.updateStatus('in_progress')
          break
        case 'request_quote':
          if (this.sendRequestQuoteTemplate) {
            this.sendRequestQuoteForm.email_message =
              this.sendRequestQuoteTemplate.description
          }
          this.dialogSendRequestQuote = true
          break
        case 'create_work_order':
          if (this.sendWorkOrderTemplate) {
            this.sendWorkOrderForm.email_message =
              this.sendWorkOrderTemplate.description
          }
          this.dialogSendWorkOrder = true
          break
        default:
          console.log('Unknown action type')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.maintenance-details {
  &__title {
    padding-right: 10px;
  }

  &__status_action {
    display: flex;
    justify-content: flex-end;
    justify-items: center;
    gap: 20px;
  }

  &__status > .status {
    cursor: pointer;
    height: 100%;
    border-radius: 4px;
  }

  &__divider {
    max-width: 500px;
  }

  @media (max-width: 768px) {
    &__divider {
      max-width: 100%;
    }
  }
}
.email-message-preview {
  padding: 20px;
  background-color: #f1f3f4;
  color: black;
}
</style>
