const STATUS = [
  {
    text: 'Pending',
    value: 'pending',
    tier: 1,
  },
  {
    text: 'In progress',
    value: 'in_progress',
    tier: 2,
  },
  {
    text: 'Cancelled',
    value: 'cancelled',
    tier: 3,
  },
  {
    text: 'Finished',
    value: 'finished',
    tier: 3,
  },
  {
    text: 'Confirmed',
    value: 'confirmed',
    tier: 4,
  },
]

export default STATUS
