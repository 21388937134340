<template>
  <div v-if="maintenance" class="maintenance-form">
    <v-row>
      <v-col cols="12" md="6">
        <v-form ref="form" class="maintenance-form__form">
          <div v-if="hasUser && hasBuilding" class="maintenance-form__user">
            <v-row v-if="!!maintenance.deleted_at">
              <v-col cols="12" class="mb-0 pb-0">
                <v-alert
                  dense
                  outlined
                  type="warning"
                  color="orange darken-3"
                  class="mb-0"
                >
                  This maintenance request is <strong>archived</strong>.
                </v-alert>
              </v-col>
            </v-row>

            <h3 class="py-5 primary--text">User Information</h3>
            <v-row>
              <v-col cols="12" md="8">
                <label class="text-field-label">Name</label>
                <v-text-field
                  v-model="user.full_name"
                  readonly
                  hide-details
                  placeholder="Requester"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <label class="text-field-label">Requested Date</label>
                <v-text-field
                  :value="dateFormat(maintenance.created_at, 'DD/MM/YYYY')"
                  readonly
                  hide-details
                  placeholder="Requester"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" v-if="user.property">
                <label class="text-field-label">Property</label>
                <v-text-field
                  v-model="user.property.name"
                  readonly
                  hide-details
                  placeholder="property"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="8">
                <label class="text-field-label">Building</label>
                <v-text-field
                  v-model="building.name"
                  readonly
                  hide-details
                  placeholder="Building Name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="8">
                <label class="text-field-label">Email</label>
                <v-text-field
                  v-model="user.email"
                  readonly
                  hide-details
                  placeholder="Email"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <label class="text-field-label">Number</label>
                <v-text-field
                  v-model="user.phone_number"
                  readonly
                  hide-details
                  placeholder="Phone number"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>

          <h3 class="py-5 primary--text">{{ header }}</h3>
          <v-row>
            <v-col cols="12" md="6">
              <label class="text-field-label">Status</label>
              <SelectStatus
                flat
                solo
                hide-details="auto"
                class="mt-2"
                v-model="form.status"
                :list="statuses"
                :status="form.status"
                :value="form.status"
                :error-messages="form.$getError('status')"
                :loading="form.$busy"
                :disabled="form.$busy || isDisabled"
              />
            </v-col>
            <v-col cols="12" md="6">
              <label class="text-field-label">Updated At</label>
              <v-text-field
                v-model="maintenance.updatedDate"
                flat
                solo
                required
                class="mt-2"
                placeholder="Updated At"
                hide-details="auto"
              />
            </v-col>
            <v-col cols="12" md="6">
              <label class="text-field-label">Category</label>
              <SelectCategory
                flat
                solo
                required
                class="mt-2"
                hide-details="auto"
                v-model="form.category_id"
                :pre-select="!isUpdate"
                :error-messages="form.$getError('category_id')"
                :loading="form.$busy"
                :disabled="form.$busy || isDisabled"
              />
            </v-col>
            <v-col cols="12" md="6">
              <label class="text-field-label">Urgency</label>
              <SelectUrgency
                flat
                solo
                required
                class="mt-2"
                hide-details="auto"
                v-model="form.urgency_id"
                :pre-select="!isUpdate"
                :error-messages="form.$getError('urgency_id')"
                :loading="form.$busy"
                :disabled="form.$busy || isDisabled"
              />
            </v-col>
            <v-col cols="12" md="6">
              <label class="text-field-label">Owner</label>
              <SelectUser
                flat
                solo
                required
                class="mt-2"
                v-model="form.owner_id"
                :building-id="building.id"
                :error-messages="form.$getError('owner_id')"
                :pre-select="!isUpdate"
                :loading="form.$busy"
                :disabled="form.$busy"
              />
            </v-col>
            <v-col cols="12" md="6">
              <label class="text-field-label">Access Contact</label>
              <SelectUser
                flat
                solo
                required
                class="mt-2"
                v-model="form.access_contact_id"
                :building-id="building.id"
                :error-messages="form.$getError('access_contact_id')"
                :pre-select="!isUpdate"
                :loading="form.$busy"
                :disabled="form.$busy"
              />
            </v-col>
            <v-col cols="12" md="6">
              <label class="text-field-label">Tags</label>
              <SelectTag
                flat
                solo
                required
                class="mt-2"
                v-model="form.tag_id"
                :building-id="building.id"
                :error-messages="form.$getError('tag_id')"
                :pre-select="!isUpdate"
                :loading="form.$busy"
                :disabled="form.$busy"
                :multiple="true"
              />
            </v-col>
            <v-col cols="12" md="6">
              <label class="text-field-label">Entry Permission</label>
              <SelectEntryPermission
                flat
                solo
                required
                class="mt-2"
                v-model="form.entry_permission_id"
                :building-id="building.id"
                :error-messages="form.$getError('entry_permission_id')"
                :pre-select="!isUpdate"
                :loading="form.$busy"
                :disabled="form.$busy"
              />
            </v-col>
            <v-col cols="12">
              <v-checkbox
                v-model="form.common_area"
                hide-details="auto"
                label="Common Area"
                class="mt-0"
              ></v-checkbox>
            </v-col>
            <v-col cols="12">
              <label class="text-field-label">Asset</label>
              <SelectAsset
                flat
                solo
                required
                class="mt-2"
                hide-details="auto"
                :multiple="true"
                v-model="form.asset_id"
                :pre-select="!isUpdate"
                :error-messages="form.$getError('asset_id')"
                :loading="form.$busy"
                :disabled="form.$busy || isDisabled"
              />
            </v-col>
            <v-col cols="12">
              <label class="text-field-label">Description</label>
              <v-textarea
                flat
                solo
                required
                hide-details="auto"
                class="mt-2"
                placeholder="Description"
                v-model="form.description"
                :error-messages="form.$getError('description')"
                :loading="form.$busy"
                :disabled="form.$busy || isDisabled"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <label class="text-field-label">Notes</label>
              <content-editor v-model="form.notes" />
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col cols="12" md="6">
        <RequestFileUpload :disabled="isDisabled" :for-update="true" />

        <div>
          <div class="appbar mt-7 mb-3">
            <v-tabs>
              <v-tab @change="currentTab = 'activity'"> Activity </v-tab>
              <v-tab @change="currentTab = 'related'"> Related </v-tab>
            </v-tabs>
          </div>
          <div v-show="isActivityTab">
            <RequestComment :maintenance-id="maintenance.id" />
            <v-spacer></v-spacer>
            <RequestLog :maintenance-id="maintenance.id" />
          </div>
          <div v-show="!isActivityTab">
            <RequestRelated :maintenance-id="maintenance.id" />
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <div class="mt-4">
          <v-btn
            v-if="hasPermissions"
            type="submit"
            color="primary"
            class="mr-4 px-6"
            height="40px"
            width="100%"
            :loading="form.$busy"
            :disabled="isDisabled"
            @click="submit"
            >Update</v-btn
          >
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Maintenance Form
 * ==================================================================================
 **/
import { mapState, mapActions } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import SelectStatus from '@/components/fields/SelectStatus'
import SelectCategory from '@/components/fields/SelectCategory'
import SelectUrgency from '@/components/fields/SelectUrgency'
import RequestFileUpload from '@/views/Home/Maintenance/Requests/components/RequestFileUpload'
import STATUS from '@/utils/enums/MaintenanceStatus'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import { dateFormat } from '@/utils/date'
import SelectUser from '@/components/fields/SelectUser.vue'
import RequestComment from '@/views/Home/Maintenance/Requests/components/RequestComment.vue'
import RequestRelated from '@/views/Home/Maintenance/Requests/components/RequestRelated.vue'
import RequestLog from '@/views/Home/Maintenance/Requests/components/RequestLog.vue'
import SelectTag from '@/components/fields/SelectTag.vue'
import SelectEntryPermission from '@/components/fields/SelectEntryPermission.vue'
import SelectAsset from '@/components/fields/SelectAsset.vue'
import ContentEditor from '@/views/Home/Settings/PageManager/components/ContentEditor.vue'

export default {
  components: {
    ContentEditor,
    SelectAsset,
    SelectEntryPermission,
    SelectTag,
    RequestLog,
    RequestRelated,
    RequestComment,
    SelectUser,
    SelectStatus,
    SelectCategory,
    SelectUrgency,
    RequestFileUpload,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  props: {
    maintenance: {
      type: Object,
      default: () => {
        return null
      },
    },
  },

  data() {
    return {
      form: new Form({
        building_id: '',
        category_id: '',
        urgency_id: '',
        owner_id: '',
        access_contact_id: '',
        asset_id: '',
        tag_id: '',
        entry_permission_id: '',
        title: '',
        status: '',
        description: '',
        notes: '',
        common_area: false,
      }),
      options: {},
      loadingAction: false,
      currentTab: 'activity',
    }
  },

  computed: {
    ...mapState({
      permissions: (state) => state.auth.permissions,
    }),

    header() {
      return 'Maintenance Information'
    },

    statuses() {
      return STATUS
    },

    user() {
      return this.maintenance && this.maintenance.user
        ? this.maintenance.user
        : false
    },

    building() {
      return this.maintenance && this.maintenance.building
        ? this.maintenance.building
        : false
    },

    hasUser() {
      return !!this.user
    },

    hasBuilding() {
      return !!this.building
    },

    isDisabled() {
      return this.maintenance ? !!this.maintenance.deleted_at : false
    },

    isUpdate() {
      return !!this.maintenance
    },

    hasPermissions() {
      return validatePermissions(
        [PERMISSION.MAINTENANCES_UPDATE],
        this.permissions
      )
    },
    isActivityTab() {
      return this.currentTab === 'activity'
    },
  },

  watch: {
    maintenance(newValue, oldValue) {
      this.initForm()
    },
  },

  methods: {
    dateFormat,
    ...mapActions({
      updateMaintenance: 'maintenance/updateMaintenance',
    }),

    initForm() {
      if (this.maintenance) {
        this.form.tag_id = [...this.maintenance.tags].map((item) => item.id)
        this.form.asset_id = [...this.maintenance.assets].map((item) => item.id)
        this.form.building_id = this.maintenance.building_id
        this.form.category_id = this.maintenance.category_id
        this.form.urgency_id = this.maintenance.urgency_id
        this.form.title = this.maintenance.title
        this.form.status = this.maintenance.status
        this.form.owner_id = this.maintenance.owner_id
        this.form.access_contact_id = this.maintenance.access_contact_id
        this.form.entry_permission_id = this.maintenance.entry_permission_id
        this.form.common_area = this.maintenance.common_area
        this.form.description = this.maintenance.description
        this.form.notes = this.maintenance.notes
      }
    },

    async submit() {
      if (this.form.$busy) return

      this.form.$busy = true
      this.form.$clearErrors()

      await this.updateMaintenance(this.getFormData())
        .then(() => {
          this.form.$busy = false
          this.showSnackbar('Maintenance details successfully updated!')
        })
        .catch((err) => {
          this.form.$busy = false
          this.form.$setErrors(this.getValidationErrors(err))
        })
    },

    getFormData() {
      let form = this.form.$data()
      if (this.isUpdate) {
        form.id = this.maintenance.id
        form.user_id = this.user.id
        form.building_id = this.building.id
      }

      return form
    },
  },
}
</script>
<style lang="scss">
.maintenance-form {
  .v-textarea {
    textarea {
      min-height: 75px;
    }
  }
}
</style>
