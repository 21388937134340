const ACTIONS = [
  {
    text: 'Request Quote',
    value: 'request_quote',
    tier: 1,
  },
  {
    text: 'Add Quote',
    value: 'add_quote',
    tier: 2,
  },
  {
    text: 'Create Work Order',
    value: 'create_work_order',
    tier: 3,
  },
  {
    text: 'Send For Inspection',
    value: 'send_for_inspection',
    tier: 3,
  },
]

export default ACTIONS
