<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :style="{ backgroundColor: '#606060' }"
        height="40px"
        dark
        v-bind="attrs"
        :loading="loading"
        v-on="on"
      >
        <v-icon class="mr-2">{{ icons.chevronDown }}</v-icon>
        Actions
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-for="(item, index) in items" :key="index">
        <v-list-item-title
          @click="onChange(item.value)"
          :style="{ cursor: 'pointer' }"
          >{{ item.text }}</v-list-item-title
        >
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import ACTIONS from '@/utils/enums/MaintenanceActions'
import { mapMutations } from 'vuex'
import { mdiChevronDown } from '@mdi/js'

export default {
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    valueColumn: {
      type: String,
      default: 'value',
    },
    textColumn: {
      type: String,
      default: 'text',
    },
  },

  data() {
    return {
      placeholder: 'Actions',
      items: ACTIONS,
      icons: {
        chevronDown: mdiChevronDown,
      },
    }
  },

  methods: {
    ...mapMutations({
      setCurrentAction: 'maintenance/setCurrentAction',
    }),

    onChange(value) {
      this.setCurrentAction(value)
    },
  },
}
</script>

<style lang="scss" scoped>
.select-actions {
  padding-top: 0;
  margin-top: 0 !important;

  input {
    width: auto !important;
    transition: width 0.3s ease;
  }
}
</style>
